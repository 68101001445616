<template lang="pug">
  Modal(
    :mask-closable="true"
    :style="{top: '50px', margin: '0px auto 50px'}"
    :value="visible"
    @on-visible-change="visibleChange"
    @on-cancel="onClose"
  )
    p(slot="header")
      span {{ editType === 'new' ? $t('Add message template') : $t('Edit message template') }}

    Form.modal-editor(
      :model="formData"
      ref="messageTemplateEditorForm"
      :rules="messageTemplateEditorRule"
    )
      template(v-if="formData")
        .content-part
          FormItem(prop="text")
            Row
              Col.title(span="9")
                h3 {{$t('Message template text')}}
              Col.val(span="14" offset="1")
                Input(
                  v-model="formData.data.text"
                  :placeholder="$t('Please input message template text')"
                )

          FormItem(prop="tags")
            Row
              Col.title(span="9")
                h3 {{$t('Message template tags')}}
              Col(span="14" offset="1")
                Select(
                  multiple
                  filterable
                  v-model="formData.data.tags"
                  :placeholder="$t('Please select message template tags')"
                )
                  Option(
                    v-for="(mytag, i) in messageTags"
                    :value="mytag"
                    :key="`${i}-${mytag}`"
                  ) {{ $t(`MessageTags.${mytag}`) }}

    .footer(slot='footer')
      Button(
        icon="md-close"
        @click="onClose"
        :disabled="isUpdating"
      ) {{ $t('Cancel') }}
      Button(
        icon="md-checkmark"
        type="primary"
        @click="handleSubmit"
        :loading="isUpdating"
      ) {{ $t('Confirm') }}
</template>
<script>
export default {
  name: 'message-template-editor',
  props: {
    formData: Object,
    editType: String,
    onClose: Function,
    onUpdate: Function,
    visible: Boolean
  },
  mixins: [],
  data () {
    return {
      isUpdating: false,
      messageTags: [
        'all',
        'mg0',
        'mg1',
        'mg2',
        'mg3',
        'mg4',
        'mg6',
        'mg7',
        'cus'
      ]
    }
  },
  watch: {
    formData: {
      handler (nv, ov) {
        if (!this.formData) return
        if (this.formData && this.formData.isReady && !this.formData.isDirty) {
          this.formData.isDirty = this.formData.isReady && !this.formData.isDirty
        }
      },
      deep: true
    }
  },
  computed: {
    messageTemplateEditorRule () {
      return {
        'data.text': [{ required: true, message: this.$t('Please input message template text'), trigger: 'blur' }]
      }
    }
  },
  methods: {
    async visibleChange (boolean) {
      if (!boolean) {
        this.isUpdating = false
        return this.onClose()
      }
      this.formData.isReady = true
      // if (!_.size(this.formData.operators)) this.formData.operators = [defaultOperator]
      // if (!_.size(this.formData.games)) this.formData.games = [defaultGame]
    },
    verifySelector (field) {
      return (rule, value, callback) => {
        if (!value) {
          return callback(new Error(this.$t(`Please select message template ${field}`)))
        }
        callback()
      }
    },
    verifyNumber (rule, value, callback) {
      if (!value && value * 1 !== 0) {
        return callback(new Error(this.$t(`This field is required`)))
      } else if (!_.isNumber(value) && value * 1 !== 0) {
        return callback(new Error(this.$t(`Input number`)))
      }
      callback()
    },
    handleSubmit () {
      this.$refs.messageTemplateEditorForm.validate(valid => {
        if (!valid) return false
        this.isUpdating = true
        this.onUpdate()
      })
    }
  },
  async mounted () {

  }
}
</script>
<style lang="sass">
.full-width
  width: 100%
.avatar
  padding: 0 10px
</style>
