<template lang="pug">
  .table
    AppTable.app-table(:columns="columns" :data="listData" border)
        template(slot-scope="{ row, index }" slot="text")
          span {{ row.data.text }}
        template(slot-scope="{ row, index }" slot="count")
          span {{ row.data.count }}
        template(slot-scope="{ row, index }" slot="tags")
          Tag(v-for="(tag, i) in row.data.tags" :key="`${i}-${tag}`") {{ $t(`MessageTags.${tag}`) }}
        template(slot-scope="{ row, index }" slot="actions")
          Button.actions(
            type="success"
            size="small"
            icon="md-create"
            @click="showMessageTemplateEditor(row)"
          )
    MessageTemplateEditor(
      :form-data="messageTemplateData"
      edit-type="edit"
      :visible="messageTemplateEditorVisible"
      :on-update="onMessageTemplateUpdate"
      :on-close="closeMessageTemplateEditor"
    )
</template>
<script>
import AppTable from '~c/app-table'
import { mapActions, mapGetters } from 'vuex'
import MessageTemplateEditor from './message-template-editor'
import to from 'await-to-js'

export default {
  name: 'message-template-table',
  mixins: [],
  components: {
    MessageTemplateEditor,
    AppTable
  },
  props: {
    listData: Array
  },
  data () {
    return {
      messageTemplateData: null,
      messageTemplateEditorVisible: false,
      showDeleteWarning: false
    }
  },
  computed: {
    // 嵌入 auth 的 state 資料
    ...mapGetters('auth', [
      'userInfo'
    ]),
    isSystem () {
      return this.$route.params.messageTemplateType === 'template'
    },
    columns () {
      let columns = [
        {
          title: this.$t('Message template Id'),
          key: 'key',
          width: 200,
          align: 'center'
        },
        {
          title: this.$t('Message template text'),
          slot: 'text',
          align: 'center'
        },
        {
          title: this.$t('Message template count'),
          slot: 'count',
          align: 'center'
        },
        {
          title: this.$t('Message template tags'),
          slot: 'tags',
          align: 'center'
        },
        {
          title: this.$t('Actived'),
          width: 100,
          render: this.renderSwitch,
          align: 'center'
        },
        {
          title: this.$t('Table actions'),
          slot: 'actions',
          width: 90,
          align: 'center'
        }
      ]

      columns = columns.filter(c => c.remove !== true)
      return columns
    }
  },
  methods: {
    ...mapActions('messageTemplate', {
      findMessageTemplate: 'find',
      messageTemplateUpdate: 'update',
      resetMessageTemplate: 'reset'
    }),
    showMessageTemplateEditor (row) {
      let data = _.cloneDeep(row)

      this.messageTemplateData = {
        ...data
      }
      this.messageTemplateEditorVisible = true
    },
    getOperatorName (operators) {
      let op = operators.map(op => op.fullname)
      return op.join(', ')
    },
    getStatusClass (status) {
      status = status.replace(/[A-Z]/g, letter => `-${letter.toLowerCase()}`)
      if (status[0] === '-') status = status.slice(1)
      return status
    },
    async onMessageTemplateUpdate () {
      let data = _.pick(this.messageTemplateData, [
        'data',
        '_id'
      ])

      let [err] = await to(this.messageTemplateUpdate({ id: data._id, data }))

      if (err) {
        console.log(err)
        return this.$Message.error(this.$t('Update message template error'))
      }
      this.$Message.success(this.$t('Update message template successful'))
      this.closeMessageTemplateEditor()
    },
    closeMessageTemplateEditor () {
      this.messageTemplateEditorVisible = false
      setTimeout(() => {
        this.messageTemplateData = null
      }, 500)
    },
    renderSwitch (h, { row }) {
      const { dispatch } = this.$store
      return h('i-switch', {
        props: {
          value: row.actived
        },
        on: {
          input (val) {
            // 延遲執行避免 swith 動畫卡頓
            setTimeout(() => {
              const data = {
                actived: !row.actived
              }
              dispatch('messageTemplate/update', { id: row._id, data })
            }, 200)
          }
        }
      }, [
        h('Icon', { slot: 'open', props: { type: 'md-checkmark' }, trueValue: 'Enabled' }),
        h('Icon', { slot: 'close', props: { type: 'md-close' }, falseValue: 'Disabled' })
      ])
    }
  }
}
</script>
<style lang="sass">
  .in-progress
    color: #ed4014
  .finished
    color: #19be6b
  .received, .ready
    color: #808695
</style>
