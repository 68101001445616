<template lang="pug">
.content(
  v-infinite-scroll="fetchData",
  infinite-scroll-disabled="isMessageTemplateBusy",
  :infinite-scroll-distance="scrollDistance",
  infinite-scroll-immediate-check="false"
)
  Content.app-content
    Row
      Col(span='24')
        Select.app-tool(
          v-model="tag"
          @on-change="onTagInputChange"
          filterable
        )
          Option(v-for="item in messageTags" :value="item" :key="`mt-${item}`") {{ $t(`MessageTags.${item}`) }}

        Input.app-tool(
          v-model="search"
          icon="ios-search"
          clearable
          :placeholder="$t('Search name')"
          @on-change='onSearchInputChange'
        )

        .sort-method
          ButtonGroup
            Button(
              :type="sidx === 'createdAt' ? 'primary' : 'default'"
              @click="updateSort('createdAt')"
            ) {{ $t("Latest") }}

            Button(
              :type="sidx === 'data.count' ? 'primary' : 'default'"
              @click="updateSort('data.count')"
            ) {{ $t("Most count") }}

        Button.app-tool-right(
          type="primary",
          icon="md-add",
          @click="showMessageTemplateEditor"
        ) {{ $t('Add new message-template') }}

    //- //- 主表單內容
    Row(:style="{marginTop: '16px'}")
      Col(span='24' v-if="messageTemplateList.length || !messageTemplateListLoading")
        MessageTemplateTable(
          :list-data="messageTemplateList",
        )

      Col(span='24', class="table-loading-col", v-if="messageTemplateListLoading")
        Spin(fix)
          Icon(type="ios-loading", size=18 class="spin-icon")

    MessageTemplateEditor(
      :form-data="messageTemplateData"
      edit-type="new"
      :visible="messageTemplateEditorVisible"
      :on-update="onMessageTemplateCreate"
      :on-close="closeMessageTemplateEditor"
    )

</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import MessageTemplateTable from './message-template-table'
import MessageTemplateEditor from './message-template-editor'
import Scroll from '~m/scroll'
import to from 'await-to-js'

export default {
  name: 'message-template-list',
  components: {
    MessageTemplateTable,
    MessageTemplateEditor
  },
  mixins: [Scroll],
  data () {
    return {
      messageTemplateData: null,
      messageTemplateEditorVisible: false,
      filter: null,
      tag: '',
      search: '',
      sidx: 'createdAt',
      messageTags: [
        'all',
        'mg0',
        'mg1',
        'mg2',
        'mg3',
        'mg4',
        'mg6',
        'mg7',
        'cus'
      ]
    }
  },

  watch: {
    // 監聽網址的變化進行 data 重設
    async '$route' (nv, ov) {
      this.resetSelector()
    }
  },

  computed: {
    ...mapGetters('messageTemplate', {
      messageTemplateList: 'getListData',
      messageTemplateListLoading: 'getLoadingStatus',
      hasNextMessageTemplate: 'hasNext'
    }),

    // 遊戲內容是否在讀取
    isMessageTemplateBusy () {
      return this.messageTemplateListLoading || !this.hasNextMessageTemplate
    }
  },
  methods: {
    ...mapActions('game', { getAllGames: 'findAll' }),
    ...mapActions('messageTemplate', {
      findMessageTemplate: 'find',
      createMessageTemplate: 'create',
      resetMessageTemplate: 'reset'
    }),

    updateSort (sidx) {
      let { path, query } = this.$route
      query.sidx = sidx
      this.updateRoute({ path, query })
    },

    onTagInputChange (val) {
      let { path, query } = this.$route
      query.tag = val
      this.updateRoute({ path, query })
    },

    updateRoute ({ path, query }) {
      this.$router.push({ path })
      this.$router.replace({ path, query })
    },

    // game input 用
    onGameInputChange (val) {
      let { path, query } = this.$route
      query.game = val
      this.updateRoute({ path, query })
    },

    // messageTemplatetype input 用
    onMessageTemplateTypeChange (val) {
      let { path, query } = this.$route
      query.type = val
      this.updateRoute({ path, query })
    },

    onMessageTemplateCategoryChange (val) {
      let { path, query } = this.$route
      query.category = val
      this.updateRoute({ path, query })
    },

    // search input 用
    onSearchInputChange (event) {
      clearTimeout(this.delaySearch)
      this.delaySearch = setTimeout(() => {
        let { path, query } = this.$route
        query.search = this.search
        this.updateRoute({ path, query })
      }, 1000)
    },

    // 向 API 取得 data
    // infinity scroll 需綁定
    fetchData (reset = false) {
      let { query } = this.$route
      let { search, tag, sidx } = query
      search = search === '' ? null : search
      tag = tag === '' ? 'all' : tag
      sidx = sidx === '' ? 'createdAt' : sidx

      if (reset) {
        this.resetMessageTemplate({ search, tag, sidx })
      }
      if (this.isMessageTemplateBusy) return
      this.findMessageTemplate({ search, tag, sidx })
    },

    // 依據 route 重設所有過濾條件
    async resetSelector () {
      const { search, tag, sidx } = this.$route.query
      this.search = search || ''
      this.tag = tag || 'all'
      this.sidx = sidx || 'createdAt'
      this.fetchData(true)
    },

    showMessageTemplateEditor () {
      this.messageTemplateData = {
        data: {
          text: '',
          tags: ['all']
        }
      }
      this.messageTemplateEditorVisible = true
    },
    async onMessageTemplateCreate () {
      if (!this.messageTemplateData.isDirty) {
        this.$Message.success(this.$t('Data no change'))
        return this.closeMessageTemplateEditor()
      }
      let data = _.pick(this.messageTemplateData, ['data'])
      let [err] = await to(this.createMessageTemplate({ data }))
      if (err) {
        console.log(err)
        return this.$Message.error(this.$t('Create message template error'))
      }
      this.$Message.success(this.$t('Create message template successful'))
      this.closeMessageTemplateEditor()
      this.fetchData(true)
    },

    closeMessageTemplateEditor () {
      this.messageTemplateEditorVisible = false
      setTimeout(() => {
        this.messageTemplateData = null
      }, 500)
    }
  },
  async mounted () {
    if (this.tag === '') this.tag = 'all'
    await this.resetSelector()
  },
  beforeDestroy () {
  }
}

</script>

<style lang="sass">
.sort-method
  float: left
  margin-left: 8px
</style>
